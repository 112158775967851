import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { EncryptService } from "./encrypt.service";
import { ApisService } from "./apis.service";
import config from 'capacitor.config';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  BASE_URL: String = environment.core.baseUrl.users;
  APP_VERSIOIN: String = environment.core.appVersion;
  CLIENT_KEY: String = environment.core.credential.clientKey;
  PRIVATE_KEY: String = environment.core.credential.privateKey;

  constructor(
    public http: HttpClient,
    public apisService: ApisService
  ) { }

  login(data: any, configs: any = {language: "ind"}): Observable<any> {
    const deviceId = configs.headers.device_id;

    configs.headers = {
      app_version: this.APP_VERSIOIN,
      client_key: this.CLIENT_KEY,
      device_id: deviceId,
      unit_system: "client",
    };
    configs.privateKey = this.PRIVATE_KEY;

    const headersData: any = this.apisService.preRequestHeader(data, configs);

    return this.http.post<any>(this.BASE_URL + "/login/email_password", data, { headers: headersData }).pipe(catchError(this.apisService.handleError));
  }

  logout(data: any, configs: any = {language: "ind"}): Observable<any> {
    const tokenId = configs.headers.token_id;
    configs.headers = {
      app_version: this.APP_VERSIOIN,
      client_key: this.CLIENT_KEY,
      unit_system: "client",
      token_id: tokenId,
    };
    configs.privateKey = this.PRIVATE_KEY;

    const headersData: any = this.apisService.preRequestHeader(data, configs);

    return this.http.post<any>(this.BASE_URL + "/logout", data, { headers: headersData }).pipe(catchError(this.apisService.handleError));
  }
}
