import { Component, inject, OnDestroy} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd, RouterLink, RouterLinkActive } from '@angular/router';
import { IonApp, IonRouterOutlet, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonLabel, IonImg, IonSplitPane, IonMenuToggle } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import { DevicesService } from "./services/devices.service";
import { environment } from "../environments/environment";
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { NavController, MenuController, AlertController, ToastController, LoadingController } from '@ionic/angular';
// import { IonMenuController } from '@ionic/core';
import { MenuComponent } from "./components/menu/menu.component";
import { addIcons } from "ionicons";
import { home, person } from "ionicons/icons";
import { UsersService } from "./services/users.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule, IonApp, IonRouterOutlet, MenuComponent, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonLabel, IonImg, IonSplitPane, IonMenuToggle],
})
export class AppComponent implements OnDestroy {
  activeLanguage: any = "ind";
  deviceId: any = null;
  tokenId: any = null;
  storage = new Storage();
  networkStatus: boolean = true;
  currentPage: any = "/login";
  allowMenu: boolean = false;

  subscriptionLogout: Subscription = new Subscription;
  subscriptionRegisterDevice: Subscription = new Subscription;

  loadingBlock:any = null;

  pageMenu: any = [
    {icon: "home", text: "Dashboard", target: "/home"},
    {icon: "person", text: "Account", target: "/account"}
  ];

  constructor(
    public router: Router,
    public devicesService: DevicesService,
    public navController: NavController,
    public menuCtrl: MenuController,
    public alertCtrl: AlertController,
    public usersService: UsersService,
    public toastCtrl: ToastController,
    public loadCtrl: LoadingController,
  ) {
    this.storage.create();

    this.initLanguage();
    this.initDevice();
    this.initToken();
    this.networkChangeDetect();
    Network.getStatus().then(status => {
      this.networkStatus = status.connected;
    });

    addIcons({home, person});
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  async initLanguage() {
    await this.storage.get("activeLanguage").then((res) => {
      if(res) {
        this.activeLanguage = res;
      } else {
        this.storage.set("activeLanguage", this.activeLanguage);
      }
    });
  }

  async updateLanguage(language: any) {
    await this.storage.set("activeLanguage", language);

    this.activeLanguage = language;
  }

  async initDevice() {
    await this.storage.get("deviceId").then((deviceId) => {
      if (deviceId) {
        this.deviceId = deviceId;
      } else {
        this.loopRegisterDevice();
      }
    });
  }

  async loopRegisterDevice() {
    this.registerDevice().then((res) => {
      return res;
    }).catch((err) => {
      setTimeout(() => {
        this.loopRegisterDevice();
      }, 1000);
    })
  }

  registerDevice(): Promise<any> {
    return new Promise((resolve, reject) => {
      Device.getInfo().then((infoDevice) => {

        this.devicesService.register(infoDevice, {language: this.activeLanguage}).subscribe(async (result) => {
          this.storage.set("deviceId", result.data.deviceId);
          this.deviceId = result.data.deviceId;
          
          resolve(this.deviceId);
        }, async (error) => {
          reject(error);
          throw new Error(error);
        })
      });
    });
  }

  async initToken() {
    const tokenId = await this.storage.get("tokenId") || null;
    this.tokenId = await tokenId;

    if (tokenId) {
      await this.navController.navigateRoot(["home"]);
    } else {
      await this.navController.navigateRoot(["login"]);
    }

    await this.streamMovePage();
  }
  
  async streamMovePage() {
    await this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects;
        this.currentPage = currentRoute;

        if (this.tokenId) {
          if(["/login"].includes(currentRoute)) {
            this.navController.navigateRoot(["home"]);
            this.allowMenu = true;
          }
        } else {
          if(!["/login", "/register"].includes(currentRoute)) {
            this.navController.navigateRoot(["login"]);
            this.allowMenu = false;
          }
        }
      }
    });
  }

  async movePage(target: String) {
    this.navController.navigateRoot([target]);
  }

  async setToken(tokenId: String) {
    this.storage.set("tokenId", tokenId);
    this.tokenId = tokenId;

    return this.tokenId;
  }

  async networkChangeDetect() {
    Network.addListener("networkStatusChange", (status) => {
      this.networkStatus = status.connected;
    })
  }

  async confirmLogout() {
    const alert = await this.alertCtrl.create({
      message: "Logout ?",
      mode: "ios",
      buttons: [
        {
          text: "No"
        },
        {
          text: "Yes",
          handler:async () => {
            const loading = await this.loadCtrl.create({
              mode: "ios",
              message: "Please wait"
            });
            loading.present();

            this.subscriptionLogout = await this.usersService.logout({}, {language: this.activeLanguage, headers: {token_id: this.tokenId}}).subscribe(async (result) => {
              await this.subscriptionLogout.unsubscribe();
              if(result.result && result.result == "success") {
                this.tokenId = null;
                this.storage.remove("tokenId");
                this.navController.navigateRoot(["/login"]);
              }

              loading.dismiss()
            },async (error) => {
              loading.dismiss()

              const toast = await this.toastCtrl.create({
                message: error.message,
                duration: environment.durationToast,
                position: environment.positionToast as 'bottom' | 'top' | 'middle',
                color: "danger",
                buttons: [
                  {icon: "close-circle-outline", side: "end"}
                ]
              })
              toast.present();
            })
          }
        }
      ]
    });
    await alert.present();
  }

  delay(s: number): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, (s));
    })
  }
}
