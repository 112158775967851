<ion-app>
  <ion-split-pane when="md" contentId="main-content">
    <ion-menu menuId="mainMenu" contentId="main-content" *ngIf="allowMenu === true" type="push">
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-img class="logo-menu" src="assets/images/logo-feewen-h2.png"></ion-img>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list class="menu-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let data of pageMenu; let i = index">
            <ion-item class="menu-item {{data.target===currentPage ? 'active' : ''}}" lines="none" routerDirection="root" [routerLink]="[data.target]"  detail="false" routerLinkActive="selected">
              <ion-icon [name]="data.icon" slot="start"></ion-icon>
              <ion-label>{{data.text}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
