import { Injectable } from '@angular/core';
import * as forge from "node-forge";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  constructor() { }

  signData(data: any, privateKey: any) {
    if (typeof data == "object") data = JSON.stringify(data);

    const key = forge.pki.privateKeyFromPem(atob(privateKey));
    const md = forge.md.sha256.create();
    md.update(data, 'utf8');

    const pss = forge.pss.create({
      md: forge.md.sha256.create(),
      mgf: forge.mgf.mgf1.create(forge.md.sha256.create()),
      saltLength: 20
    })

    const signature = key.sign(md, pss);
    const signatureEncode =  forge.util.encode64(signature);

    return signatureEncode;
  }
}
