import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from "../../environments/environment";
import { EncryptService } from "./encrypt.service";

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  constructor(
    public http: HttpClient,
    public encryptService: EncryptService
  ) { }

  preRequestHeader(data: any, config: any = {language: "ind"}) {
    const timeId: any = new Date().getTime();
    const language = config.language || "ind";
  
    let header: any = {};

    let headerKey = ["time_id", "client_key", "device_id", "token_id", "unit_system", "app_version"];
    headerKey.sort();
    headerKey.forEach((key) => {
      if (key == "time_id") header[key] = timeId;
      else if (config.headers[key] !== undefined) header[key] = config.headers[key];
    });

    const preSignature = {
      header: header,
      body: data
    };

    const privateKey = config.privateKey || null;

    if (privateKey) {
      const signature: any = this.encryptService.signData(preSignature, privateKey);
      header["x-signature"] = signature;
    }

    header.language = language;

    return header;
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    let message = "";
    if(error.error) {
      message = error.error.message;
    } else {
      message = error.message;
    }

    const responseError = {
      message: message,
      errorCode: error.error.errorCode || null
    }

    return throwError(responseError);
  }
}
